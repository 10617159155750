export const countries = [
  {
    name: 'United States',
    id: 246,
    alpha2: 'US',
  },
];

export const states = [
  { name: 'Alabama', id: 4660, country_id: 246, abbreviation: 'AL', is_active: 0 },
  { name: 'Alaska', id: 4661, country_id: 246, abbreviation: 'AK', is_active: 0 },
  { name: 'Arizona', id: 4663, country_id: 246, abbreviation: 'AZ', is_active: 0 },
  { name: 'Arkansas', id: 4664, country_id: 246, abbreviation: 'AR', is_active: 0 },
  { name: 'California', id: 4665, country_id: 246, abbreviation: 'CA', is_active: 0 },
  { name: 'Colorado', id: 4666, country_id: 246, abbreviation: 'CO', is_active: 0 },
  { name: 'Connecticut', id: 4667, country_id: 246, abbreviation: 'CT', is_active: 0 },
  { name: 'Delaware', id: 4668, country_id: 246, abbreviation: 'DE', is_active: 0 },
  { name: 'District of Columbia', id: 4669, country_id: 246, abbreviation: 'DC', is_active: 0 },
  { name: 'Florida', id: 4670, country_id: 246, abbreviation: 'FL', is_active: 0 },
  { name: 'Georgia', id: 4671, country_id: 246, abbreviation: 'GA', is_active: 0 },
  { name: 'Hawaii', id: 4673, country_id: 246, abbreviation: 'HI', is_active: 0 },
  { name: 'Idaho', id: 4674, country_id: 246, abbreviation: 'ID', is_active: 0 },
  { name: 'Illinois', id: 4675, country_id: 246, abbreviation: 'IL', is_active: 0 },
  { name: 'Indiana', id: 4676, country_id: 246, abbreviation: 'IN', is_active: 0 },
  { name: 'Iowa', id: 4677, country_id: 246, abbreviation: 'IA', is_active: 0 },
  { name: 'Kansas', id: 4678, country_id: 246, abbreviation: 'KS', is_active: 0 },
  { name: 'Kentucky', id: 4679, country_id: 246, abbreviation: 'KY', is_active: 0 },
  { name: 'Louisiana', id: 4680, country_id: 246, abbreviation: 'LA', is_active: 0 },
  { name: 'Maine', id: 4681, country_id: 246, abbreviation: 'ME', is_active: 0 },
  { name: 'Maryland', id: 4682, country_id: 246, abbreviation: 'MD', is_active: 0 },
  { name: 'Massachusetts', id: 4683, country_id: 246, abbreviation: 'MA', is_active: 0 },
  { name: 'Michigan', id: 4684, country_id: 246, abbreviation: 'MI', is_active: 0 },
  { name: 'Minnesota', id: 4685, country_id: 246, abbreviation: 'MN', is_active: 0 },
  { name: 'Mississippi', id: 4686, country_id: 246, abbreviation: 'MS', is_active: 0 },
  { name: 'Missouri', id: 4687, country_id: 246, abbreviation: 'MO', is_active: 0 },
  { name: 'Montana', id: 4688, country_id: 246, abbreviation: 'MT', is_active: 0 },
  { name: 'Nebraska', id: 4689, country_id: 246, abbreviation: 'NE', is_active: 0 },
  { name: 'Nevada', id: 4690, country_id: 246, abbreviation: 'NV', is_active: 0 },
  { name: 'New Hampshire', id: 4691, country_id: 246, abbreviation: 'NH', is_active: 0 },
  { name: 'New Jersey', id: 4692, country_id: 246, abbreviation: 'NJ', is_active: 0 },
  { name: 'New Mexico', id: 4693, country_id: 246, abbreviation: 'NM', is_active: 0 },
  { name: 'New York', id: 4694, country_id: 246, abbreviation: 'NY', is_active: 0 },
  { name: 'North Carolina', id: 4695, country_id: 246, abbreviation: 'NC', is_active: 0 },
  { name: 'North Dakota', id: 4696, country_id: 246, abbreviation: 'ND', is_active: 0 },
  { name: 'Ohio', id: 4698, country_id: 246, abbreviation: 'OH', is_active: 0 },
  { name: 'Oklahoma', id: 4699, country_id: 246, abbreviation: 'OK', is_active: 0 },
  { name: 'Oregon', id: 4700, country_id: 246, abbreviation: 'OR', is_active: 0 },
  { name: 'Pennsylvania', id: 4701, country_id: 246, abbreviation: 'PA', is_active: 0 },
  { name: 'Rhode Island', id: 4703, country_id: 246, abbreviation: 'RI', is_active: 0 },
  { name: 'South Carolina', id: 4704, country_id: 246, abbreviation: 'SC', is_active: 0 },
  { name: 'South Dakota', id: 4705, country_id: 246, abbreviation: 'SD', is_active: 0 },
  { name: 'Tennessee', id: 4706, country_id: 246, abbreviation: 'TN', is_active: 0 },
  { name: 'Texas', id: 4707, country_id: 246, abbreviation: 'TX', is_active: 0 },
  { name: 'Utah', id: 4709, country_id: 246, abbreviation: 'UT', is_active: 0 },
  { name: 'Vermont', id: 4710, country_id: 246, abbreviation: 'VT', is_active: 0 },
  { name: 'Virginia', id: 4712, country_id: 246, abbreviation: 'VA', is_active: 0 },
  { name: 'Washington', id: 4713, country_id: 246, abbreviation: 'WA', is_active: 0 },
  { name: 'West Virginia', id: 4714, country_id: 246, abbreviation: 'WV', is_active: 0 },
  { name: 'Wisconsin', id: 4715, country_id: 246, abbreviation: 'WI', is_active: 0 },
  { name: 'Wyoming', id: 4716, country_id: 246, abbreviation: 'WY', is_active: 0 },
  {
    name: 'American Samoa',
    id: 4662,
    country_id: 246,
    abbreviation: 'AS',
    is_active: 0,
  },
  {
    name: 'Guam',
    id: 4672,
    country_id: 246,
    abbreviation: 'GU',
    is_active: 0,
  },
  {
    name: 'Northern Mariana Islands',
    id: 4697,
    country_id: 246,
    abbreviation: 'MP',
    is_active: 0,
  },
  {
    name: 'Puerto Rico',
    id: 4702,
    country_id: 246,
    abbreviation: 'PR',
    is_active: 0,
  },
  {
    name: 'United States Minor Outlying Islands',
    id: 4708,
    country_id: 246,
    abbreviation: 'UM',
    is_active: 0,
  },
  {
    name: 'Virgin Islands, U.S.',
    id: 4711,
    country_id: 246,
    abbreviation: 'VI',
    is_active: 0,
  },
];

export const deviceTypes = [
  { name: 'Desktop', id: 15 },
  { name: 'Mobile Phone', id: 11 },
  { name: 'Tablet', id: 12 },
  { name: 'Smart TV', id: 13 },
  { name: 'Other Embedded', id: 14 },
];

export const creativesTypesAndSizes = [
  {
    value: 0,
    label: 'Display',
    options: [
      { value: '300x250', label: '300x250' },
      { value: '320x50', label: '320x50' },
      { value: '728x90', label: '728x90' },
      { value: '160x600', label: '160x600' },
      { value: 'Other Display Size', label: 'Other' },
    ],
  },
  {
    value: 1,
    label: 'Video',
    options: [
      { value: '30 sec', label: '30 sec' },
      { value: '15 sec', label: '15 sec' },
      { value: '10 sec', label: '10 sec' },
      { value: '< 30 sec', label: '< 30 sec' },
      { value: '> 30 sec', label: '> 30 sec' },
    ],
  },
];

export const audiences = [
  {
    value: 'ageGroup',
    label: 'Age Group',
    options: [
      { id: 10100001, name: '13-17' },
      { id: 10100002, name: '18-24' },
      { id: 10100003, name: '25-34' },
      { id: 10100004, name: '35-49' },
      { id: 10100005, name: '50-64' },
      { id: 10100006, name: '65+' },
    ],
  },
  {
    value: 'gender',
    label: 'Gender',
    options: [
      { id: 10200002, name: 'Female' },
      { id: 10200001, name: 'Male' },
    ],
  },
  {
    value: 'income',
    label: 'Income',
    options: [
      { id: 10300001, name: '$1k-$14k' },
      { id: 10300002, name: '$15k-$24k' },
      { id: 10300003, name: '$25k-$34k' },
      { id: 10300004, name: '$35k-$49k' },
      { id: 10300005, name: '$50k-$74k' },
      { id: 10300006, name: '$75k-$99k' },
      { id: 10300007, name: '$100k-$124k' },
      { id: 10300008, name: '$125k-$149k' },
      { id: 10300009, name: '$150k-$174k' },
      { id: 10300010, name: '$175k-$199k' },
      { id: 10300011, name: '$200k-$249k' },
      { id: 10300012, name: '$250k+' },
    ],
  },
];

export const publisherCategories = [
  {
    id: 12,
    name: 'Books & Literature (IAB1-1)',
    group: 'Arts & Entertainment',
    iab_id: 'IAB1-1',
    parent_id: 11,
  },
  {
    id: 13,
    name: 'Celebrity Fan/Gossip (IAB1-2)',
    group: 'Arts & Entertainment',
    iab_id: 'IAB1-2',
    parent_id: 11,
  },
  {
    id: 14,
    name: 'Fine Art (IAB1-3)',
    group: 'Arts & Entertainment',
    iab_id: 'IAB1-3',
    parent_id: 11,
  },
  {
    id: 15,
    name: 'Humor (IAB1-4)',
    group: 'Arts & Entertainment',
    iab_id: 'IAB1-4',
    parent_id: 11,
  },
  {
    id: 16,
    name: 'Movies (IAB1-5)',
    group: 'Arts & Entertainment',
    iab_id: 'IAB1-5',
    parent_id: 11,
  },
  {
    id: 17,
    name: 'Music (IAB1-6)',
    group: 'Arts & Entertainment',
    iab_id: 'IAB1-6',
    parent_id: 11,
  },
  {
    id: 18,
    name: 'Television (IAB1-7)',
    group: 'Arts & Entertainment',
    iab_id: 'IAB1-7',
    parent_id: 11,
  },
  { id: 20, name: 'Auto Parts (IAB2-1)', group: 'Automotive', iab_id: 'IAB2-1', parent_id: 19 },
  { id: 21, name: 'Auto Repair (IAB2-2)', group: 'Automotive', iab_id: 'IAB2-2', parent_id: 19 },
  {
    id: 22,
    name: 'Buying/Selling Cars (IAB2-3)',
    group: 'Automotive',
    iab_id: 'IAB2-3',
    parent_id: 19,
  },
  { id: 23, name: 'Car Culture (IAB2-4)', group: 'Automotive', iab_id: 'IAB2-4', parent_id: 19 },
  {
    id: 24,
    name: 'Certified Pre-Owned (IAB2-5)',
    group: 'Automotive',
    iab_id: 'IAB2-5',
    parent_id: 19,
  },
  { id: 25, name: 'Convertible (IAB2-6)', group: 'Automotive', iab_id: 'IAB2-6', parent_id: 19 },
  { id: 26, name: 'Coupe (IAB2-7)', group: 'Automotive', iab_id: 'IAB2-7', parent_id: 19 },
  { id: 27, name: 'Crossover (IAB2-8)', group: 'Automotive', iab_id: 'IAB2-8', parent_id: 19 },
  { id: 28, name: 'Diesel (IAB2-9)', group: 'Automotive', iab_id: 'IAB2-9', parent_id: 19 },
  {
    id: 29,
    name: 'Electric Vehicle (IAB2-10)',
    group: 'Automotive',
    iab_id: 'IAB2-10',
    parent_id: 19,
  },
  { id: 30, name: 'Hatchback (IAB2-11)', group: 'Automotive', iab_id: 'IAB2-11', parent_id: 19 },
  { id: 31, name: 'Hybrid (IAB2-12)', group: 'Automotive', iab_id: 'IAB2-12', parent_id: 19 },
  { id: 32, name: 'Luxury (IAB2-13)', group: 'Automotive', iab_id: 'IAB2-13', parent_id: 19 },
  { id: 33, name: 'MiniVan (IAB2-14)', group: 'Automotive', iab_id: 'IAB2-14', parent_id: 19 },
  { id: 34, name: 'Mororcycles (IAB2-15)', group: 'Automotive', iab_id: 'IAB2-15', parent_id: 19 },
  {
    id: 35,
    name: 'Off-Road Vehicles (IAB2-16)',
    group: 'Automotive',
    iab_id: 'IAB2-16',
    parent_id: 19,
  },
  {
    id: 36,
    name: 'Performance Vehicles (IAB2-17)',
    group: 'Automotive',
    iab_id: 'IAB2-17',
    parent_id: 19,
  },
  { id: 37, name: 'Pickup (IAB2-18)', group: 'Automotive', iab_id: 'IAB2-18', parent_id: 19 },
  {
    id: 38,
    name: 'Road-Side Assistance (IAB2-19)',
    group: 'Automotive',
    iab_id: 'IAB2-19',
    parent_id: 19,
  },
  { id: 39, name: 'Sedan (IAB2-20)', group: 'Automotive', iab_id: 'IAB2-20', parent_id: 19 },
  {
    id: 40,
    name: 'Trucks & Accessories (IAB2-21)',
    group: 'Automotive',
    iab_id: 'IAB2-21',
    parent_id: 19,
  },
  { id: 41, name: 'Vintage Cars (IAB2-22)', group: 'Automotive', iab_id: 'IAB2-22', parent_id: 19 },
  { id: 42, name: 'Wagon (IAB2-23)', group: 'Automotive', iab_id: 'IAB2-23', parent_id: 19 },
  { id: 44, name: 'Advertising (IAB3-1)', group: 'Business', iab_id: 'IAB3-1', parent_id: 43 },
  { id: 45, name: 'Agriculture (IAB3-2)', group: 'Business', iab_id: 'IAB3-2', parent_id: 43 },
  {
    id: 46,
    name: 'Biotech/Biomedical (IAB3-3)',
    group: 'Business',
    iab_id: 'IAB3-3',
    parent_id: 43,
  },
  {
    id: 47,
    name: 'Business Software (IAB3-4)',
    group: 'Business',
    iab_id: 'IAB3-4',
    parent_id: 43,
  },
  { id: 48, name: 'Construction (IAB3-5)', group: 'Business', iab_id: 'IAB3-5', parent_id: 43 },
  { id: 49, name: 'Forestry (IAB3-6)', group: 'Business', iab_id: 'IAB3-6', parent_id: 43 },
  { id: 50, name: 'Government (IAB3-7)', group: 'Business', iab_id: 'IAB3-7', parent_id: 43 },
  { id: 51, name: 'Green Solutions (IAB3-8)', group: 'Business', iab_id: 'IAB3-8', parent_id: 43 },
  { id: 52, name: 'Human Resources (IAB3-9)', group: 'Business', iab_id: 'IAB3-9', parent_id: 43 },
  { id: 53, name: 'Logistics (IAB3-10)', group: 'Business', iab_id: 'IAB3-10', parent_id: 43 },
  { id: 54, name: 'Marketing (IAB3-11)', group: 'Business', iab_id: 'IAB3-11', parent_id: 43 },
  { id: 55, name: 'Metals (IAB3-12)', group: 'Business', iab_id: 'IAB3-12', parent_id: 43 },
  { id: 57, name: 'Career Planning (IAB4-1)', group: 'Careers', iab_id: 'IAB4-1', parent_id: 56 },
  { id: 58, name: 'College (IAB4-2)', group: 'Careers', iab_id: 'IAB4-2', parent_id: 56 },
  { id: 59, name: 'Financial Aid (IAB4-3)', group: 'Careers', iab_id: 'IAB4-3', parent_id: 56 },
  { id: 60, name: 'Job Fairs (IAB4-4)', group: 'Careers', iab_id: 'IAB4-4', parent_id: 56 },
  { id: 61, name: 'Job Search (IAB4-5)', group: 'Careers', iab_id: 'IAB4-5', parent_id: 56 },
  {
    id: 62,
    name: 'Resume Writing/Advice (IAB4-6)',
    group: 'Careers',
    iab_id: 'IAB4-6',
    parent_id: 56,
  },
  { id: 63, name: 'Nursing (IAB4-7)', group: 'Careers', iab_id: 'IAB4-7', parent_id: 56 },
  { id: 64, name: 'Scholarships (IAB4-8)', group: 'Careers', iab_id: 'IAB4-8', parent_id: 56 },
  { id: 65, name: 'Telecommuting (IAB4-9)', group: 'Careers', iab_id: 'IAB4-9', parent_id: 56 },
  { id: 66, name: 'U.S. Military (IAB4-10)', group: 'Careers', iab_id: 'IAB4-10', parent_id: 56 },
  { id: 67, name: 'Career Advice (IAB4-11)', group: 'Careers', iab_id: 'IAB4-11', parent_id: 56 },
  { id: 69, name: '41615 Education (IAB5-1)', group: 'Education', iab_id: 'IAB5-1', parent_id: 68 },
  { id: 70, name: 'Adult Education (IAB5-2)', group: 'Education', iab_id: 'IAB5-2', parent_id: 68 },
  { id: 71, name: 'Art History (IAB5-3)', group: 'Education', iab_id: 'IAB5-3', parent_id: 68 },
  {
    id: 72,
    name: 'Colledge Administration (IAB5-4)',
    group: 'Education',
    iab_id: 'IAB5-4',
    parent_id: 68,
  },
  { id: 73, name: 'College Life (IAB5-5)', group: 'Education', iab_id: 'IAB5-5', parent_id: 68 },
  {
    id: 74,
    name: 'Distance Learning (IAB5-6)',
    group: 'Education',
    iab_id: 'IAB5-6',
    parent_id: 68,
  },
  {
    id: 75,
    name: 'English as a 2nd Language (IAB5-7)',
    group: 'Education',
    iab_id: 'IAB5-7',
    parent_id: 68,
  },
  {
    id: 76,
    name: 'Language Learning (IAB5-8)',
    group: 'Education',
    iab_id: 'IAB5-8',
    parent_id: 68,
  },
  { id: 77, name: 'Graduate School (IAB5-9)', group: 'Education', iab_id: 'IAB5-9', parent_id: 68 },
  { id: 78, name: 'Homeschooling (IAB5-10)', group: 'Education', iab_id: 'IAB5-10', parent_id: 68 },
  {
    id: 79,
    name: 'Homework/Study Tips (IAB5-11)',
    group: 'Education',
    iab_id: 'IAB5-11',
    parent_id: 68,
  },
  { id: 80, name: 'K-6 Educators (IAB5-12)', group: 'Education', iab_id: 'IAB5-12', parent_id: 68 },
  {
    id: 81,
    name: 'Private School (IAB5-13)',
    group: 'Education',
    iab_id: 'IAB5-13',
    parent_id: 68,
  },
  {
    id: 82,
    name: 'Special Education (IAB5-14)',
    group: 'Education',
    iab_id: 'IAB5-14',
    parent_id: 68,
  },
  {
    id: 83,
    name: 'Studying Business (IAB5-15)',
    group: 'Education',
    iab_id: 'IAB5-15',
    parent_id: 68,
  },
  {
    id: 85,
    name: 'Adoption (IAB6-1)',
    group: 'Family & Parenting',
    iab_id: 'IAB6-1',
    parent_id: 84,
  },
  {
    id: 86,
    name: 'Babies & Toddlers (IAB6-2)',
    group: 'Family & Parenting',
    iab_id: 'IAB6-2',
    parent_id: 84,
  },
  {
    id: 87,
    name: 'Daycare/Pre School (IAB6-3)',
    group: 'Family & Parenting',
    iab_id: 'IAB6-3',
    parent_id: 84,
  },
  {
    id: 88,
    name: 'Family Internet (IAB6-4)',
    group: 'Family & Parenting',
    iab_id: 'IAB6-4',
    parent_id: 84,
  },
  {
    id: 89,
    name: 'Parenting - K-6 Kids (IAB6-5)',
    group: 'Family & Parenting',
    iab_id: 'IAB6-5',
    parent_id: 84,
  },
  {
    id: 90,
    name: 'Parenting teens (IAB6-6)',
    group: 'Family & Parenting',
    iab_id: 'IAB6-6',
    parent_id: 84,
  },
  {
    id: 91,
    name: 'Pregnancy (IAB6-7)',
    group: 'Family & Parenting',
    iab_id: 'IAB6-7',
    parent_id: 84,
  },
  {
    id: 93,
    name: 'Exercise  (IAB7-1)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-1',
    parent_id: 92,
  },
  { id: 94, name: 'A.D.D. (IAB7-2)', group: 'Health & Fitness', iab_id: 'IAB7-2', parent_id: 92 },
  {
    id: 95,
    name: 'AIDS / HIV  (IAB7-3)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-3',
    parent_id: 92,
  },
  {
    id: 96,
    name: 'Allergies (IAB7-4)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-4',
    parent_id: 92,
  },
  {
    id: 97,
    name: 'Alternative Medicine  (IAB7-5)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-5',
    parent_id: 92,
  },
  {
    id: 98,
    name: 'Arthritis  (IAB7-6)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-6',
    parent_id: 92,
  },
  { id: 99, name: 'Asthma  (IAB7-7)', group: 'Health & Fitness', iab_id: 'IAB7-7', parent_id: 92 },
  {
    id: 100,
    name: 'Autism/PDD  (IAB7-8)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-8',
    parent_id: 92,
  },
  {
    id: 101,
    name: 'Bipolar Disorder  (IAB7-9)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-9',
    parent_id: 92,
  },
  {
    id: 102,
    name: 'Brain Tumor (IAB7-10)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-10',
    parent_id: 92,
  },
  {
    id: 103,
    name: 'Cancer (IAB7-11)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-11',
    parent_id: 92,
  },
  {
    id: 104,
    name: 'Cholesterol (IAB7-12)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-12',
    parent_id: 92,
  },
  {
    id: 105,
    name: 'Chronic Fatigue Syndrome (IAB7-13)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-13',
    parent_id: 92,
  },
  {
    id: 106,
    name: 'Chronic Pain (IAB7-14)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-14',
    parent_id: 92,
  },
  {
    id: 107,
    name: 'Cold & Flu (IAB7-15)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-15',
    parent_id: 92,
  },
  {
    id: 108,
    name: 'Deafness (IAB7-16)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-16',
    parent_id: 92,
  },
  {
    id: 109,
    name: 'Dental Care (IAB7-17)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-17',
    parent_id: 92,
  },
  {
    id: 110,
    name: 'Depression (IAB7-18)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-18',
    parent_id: 92,
  },
  {
    id: 111,
    name: 'Dermatology (IAB7-19)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-19',
    parent_id: 92,
  },
  {
    id: 112,
    name: 'Diabetes (IAB7-20)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-20',
    parent_id: 92,
  },
  {
    id: 113,
    name: 'Epilepsy (IAB7-21)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-21',
    parent_id: 92,
  },
  {
    id: 114,
    name: 'GERD/Acid Reflux (IAB7-22)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-22',
    parent_id: 92,
  },
  {
    id: 115,
    name: 'Headaches/Migraines (IAB7-23)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-23',
    parent_id: 92,
  },
  {
    id: 116,
    name: 'Heart Disease (IAB7-24)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-24',
    parent_id: 92,
  },
  {
    id: 117,
    name: 'Herbs for Health (IAB7-25)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-25',
    parent_id: 92,
  },
  {
    id: 118,
    name: 'Holistic Healing (IAB7-26)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-26',
    parent_id: 92,
  },
  {
    id: 119,
    name: "IBS/Crohn's Disease (IAB7-27)",
    group: 'Health & Fitness',
    iab_id: 'IAB7-27',
    parent_id: 92,
  },
  {
    id: 120,
    name: 'Incest/Abuse Support (IAB7-28)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-28',
    parent_id: 92,
  },
  {
    id: 121,
    name: 'Incontinence (IAB7-29)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-29',
    parent_id: 92,
  },
  {
    id: 122,
    name: 'Infertility (IAB7-30)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-30',
    parent_id: 92,
  },
  {
    id: 123,
    name: "Men's Health (IAB7-31)",
    group: 'Health & Fitness',
    iab_id: 'IAB7-31',
    parent_id: 92,
  },
  {
    id: 124,
    name: 'Nutrition (IAB7-32)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-32',
    parent_id: 92,
  },
  {
    id: 125,
    name: 'Orthopedics (IAB7-33)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-33',
    parent_id: 92,
  },
  {
    id: 126,
    name: 'Panic/Anxiety Disorders (IAB7-34)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-34',
    parent_id: 92,
  },
  {
    id: 127,
    name: 'Pediatrics (IAB7-35)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-35',
    parent_id: 92,
  },
  {
    id: 128,
    name: 'Physical Therapy (IAB7-36)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-36',
    parent_id: 92,
  },
  {
    id: 129,
    name: 'Psychology/Psychiatry (IAB7-37)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-37',
    parent_id: 92,
  },
  {
    id: 130,
    name: 'Senor Health (IAB7-38)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-38',
    parent_id: 92,
  },
  {
    id: 131,
    name: 'Sexuality (IAB7-39)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-39',
    parent_id: 92,
  },
  {
    id: 132,
    name: 'Sleep Disorders (IAB7-40)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-40',
    parent_id: 92,
  },
  {
    id: 133,
    name: 'Smoking Cessation (IAB7-41)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-41',
    parent_id: 92,
  },
  {
    id: 134,
    name: 'Substance Abuse (IAB7-42)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-42',
    parent_id: 92,
  },
  {
    id: 135,
    name: 'Thyroid Disease (IAB7-43)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-43',
    parent_id: 92,
  },
  {
    id: 136,
    name: 'Weight Loss (IAB7-44)',
    group: 'Health & Fitness',
    iab_id: 'IAB7-44',
    parent_id: 92,
  },
  {
    id: 137,
    name: "Women's Health (IAB7-45)",
    group: 'Health & Fitness',
    iab_id: 'IAB7-45',
    parent_id: 92,
  },
  {
    id: 139,
    name: 'American Cuisine (IAB8-1)',
    group: 'Food & Drink',
    iab_id: 'IAB8-1',
    parent_id: 138,
  },
  {
    id: 140,
    name: 'Barbecues & Grilling (IAB8-2)',
    group: 'Food & Drink',
    iab_id: 'IAB8-2',
    parent_id: 138,
  },
  {
    id: 141,
    name: 'Cajun/Creole (IAB8-3)',
    group: 'Food & Drink',
    iab_id: 'IAB8-3',
    parent_id: 138,
  },
  {
    id: 142,
    name: 'Chinese Cuisine (IAB8-4)',
    group: 'Food & Drink',
    iab_id: 'IAB8-4',
    parent_id: 138,
  },
  {
    id: 143,
    name: 'Cocktails/Beer (IAB8-5)',
    group: 'Food & Drink',
    iab_id: 'IAB8-5',
    parent_id: 138,
  },
  { id: 144, name: 'Coffee/Tea (IAB8-6)', group: 'Food & Drink', iab_id: 'IAB8-6', parent_id: 138 },
  {
    id: 145,
    name: 'Cuisine-Specific (IAB8-7)',
    group: 'Food & Drink',
    iab_id: 'IAB8-7',
    parent_id: 138,
  },
  {
    id: 146,
    name: 'Desserts & Baking (IAB8-8)',
    group: 'Food & Drink',
    iab_id: 'IAB8-8',
    parent_id: 138,
  },
  { id: 147, name: 'Dining Out (IAB8-9)', group: 'Food & Drink', iab_id: 'IAB8-9', parent_id: 138 },
  {
    id: 148,
    name: 'Food Allergies (IAB8-10)',
    group: 'Food & Drink',
    iab_id: 'IAB8-10',
    parent_id: 138,
  },
  {
    id: 149,
    name: 'French Cuisine (IAB8-11)',
    group: 'Food & Drink',
    iab_id: 'IAB8-11',
    parent_id: 138,
  },
  {
    id: 150,
    name: 'Health/Lowfat Cooking (IAB8-12)',
    group: 'Food & Drink',
    iab_id: 'IAB8-12',
    parent_id: 138,
  },
  {
    id: 151,
    name: 'Italian Cuisine (IAB8-13)',
    group: 'Food & Drink',
    iab_id: 'IAB8-13',
    parent_id: 138,
  },
  {
    id: 152,
    name: 'Japanese Cuisine (IAB8-14)',
    group: 'Food & Drink',
    iab_id: 'IAB8-14',
    parent_id: 138,
  },
  {
    id: 153,
    name: 'Mexican Cuisine (IAB8-15)',
    group: 'Food & Drink',
    iab_id: 'IAB8-15',
    parent_id: 138,
  },
  { id: 154, name: 'Vegan (IAB8-16)', group: 'Food & Drink', iab_id: 'IAB8-16', parent_id: 138 },
  {
    id: 155,
    name: 'Vegetarian (IAB8-17)',
    group: 'Food & Drink',
    iab_id: 'IAB8-17',
    parent_id: 138,
  },
  { id: 156, name: 'Wine (IAB8-18)', group: 'Food & Drink', iab_id: 'IAB8-18', parent_id: 138 },
  {
    id: 158,
    name: 'Art/Technology (IAB9-1)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-1',
    parent_id: 157,
  },
  {
    id: 159,
    name: 'Arts & Crafts (IAB9-2)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-2',
    parent_id: 157,
  },
  {
    id: 160,
    name: 'Beadwork (IAB9-3)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-3',
    parent_id: 157,
  },
  {
    id: 161,
    name: 'Birdwatching (IAB9-4)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-4',
    parent_id: 157,
  },
  {
    id: 162,
    name: 'Board Games/Puzzles (IAB9-5)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-5',
    parent_id: 157,
  },
  {
    id: 163,
    name: 'Candle & Soap Making (IAB9-6)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-6',
    parent_id: 157,
  },
  {
    id: 164,
    name: 'Card Games (IAB9-7)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-7',
    parent_id: 157,
  },
  {
    id: 165,
    name: 'Chess (IAB9-8)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-8',
    parent_id: 157,
  },
  {
    id: 166,
    name: 'Cigars (IAB9-9)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-9',
    parent_id: 157,
  },
  {
    id: 167,
    name: 'Collecting (IAB9-10)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-10',
    parent_id: 157,
  },
  {
    id: 168,
    name: 'Comic Books (IAB9-11)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-11',
    parent_id: 157,
  },
  {
    id: 169,
    name: 'Drawing/Sketching (IAB9-12)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-12',
    parent_id: 157,
  },
  {
    id: 170,
    name: 'Freelance Writing (IAB9-13)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-13',
    parent_id: 157,
  },
  {
    id: 171,
    name: 'Genealogy (IAB9-14)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-14',
    parent_id: 157,
  },
  {
    id: 172,
    name: 'Getting Published (IAB9-15)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-15',
    parent_id: 157,
  },
  {
    id: 173,
    name: 'Guitar (IAB9-16)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-16',
    parent_id: 157,
  },
  {
    id: 174,
    name: 'Home Recording (IAB9-17)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-17',
    parent_id: 157,
  },
  {
    id: 175,
    name: 'Investors & Patents (IAB9-18)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-18',
    parent_id: 157,
  },
  {
    id: 176,
    name: 'Jewelry Making (IAB9-19)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-19',
    parent_id: 157,
  },
  {
    id: 177,
    name: 'Magic & Illusion (IAB9-20)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-20',
    parent_id: 157,
  },
  {
    id: 178,
    name: 'Needlework (IAB9-21)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-21',
    parent_id: 157,
  },
  {
    id: 179,
    name: 'Painting (IAB9-22)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-22',
    parent_id: 157,
  },
  {
    id: 180,
    name: 'Photography (IAB9-23)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-23',
    parent_id: 157,
  },
  {
    id: 181,
    name: 'Radio (IAB9-24)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-24',
    parent_id: 157,
  },
  {
    id: 182,
    name: 'Roleplaying Games (IAB9-25)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-25',
    parent_id: 157,
  },
  {
    id: 183,
    name: 'Sci-Fi & Fantasy (IAB9-26)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-26',
    parent_id: 157,
  },
  {
    id: 184,
    name: 'Scrapbooking (IAB9-27)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-27',
    parent_id: 157,
  },
  {
    id: 185,
    name: 'Screenwriting (IAB9-28)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-28',
    parent_id: 157,
  },
  {
    id: 186,
    name: 'Stamps & Coins (IAB9-29)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-29',
    parent_id: 157,
  },
  {
    id: 187,
    name: 'Video & Computer Games (IAB9-30)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-30',
    parent_id: 157,
  },
  {
    id: 188,
    name: 'Woodworking (IAB9-31)',
    group: 'Hobbies & Interests',
    iab_id: 'IAB9-31',
    parent_id: 157,
  },
  {
    id: 190,
    name: 'Appliances (IAB10-1)',
    group: 'Home & Garden',
    iab_id: 'IAB10-1',
    parent_id: 189,
  },
  {
    id: 191,
    name: 'Entertaining (IAB10-2)',
    group: 'Home & Garden',
    iab_id: 'IAB10-2',
    parent_id: 189,
  },
  {
    id: 192,
    name: 'Environmental Safety (IAB10-3)',
    group: 'Home & Garden',
    iab_id: 'IAB10-3',
    parent_id: 189,
  },
  {
    id: 193,
    name: 'Gardening (IAB10-4)',
    group: 'Home & Garden',
    iab_id: 'IAB10-4',
    parent_id: 189,
  },
  {
    id: 194,
    name: 'Home Repair (IAB10-5)',
    group: 'Home & Garden',
    iab_id: 'IAB10-5',
    parent_id: 189,
  },
  {
    id: 195,
    name: 'Home Theater (IAB10-6)',
    group: 'Home & Garden',
    iab_id: 'IAB10-6',
    parent_id: 189,
  },
  {
    id: 196,
    name: 'Interior Decorating (IAB10-7)',
    group: 'Home & Garden',
    iab_id: 'IAB10-7',
    parent_id: 189,
  },
  {
    id: 197,
    name: 'Landscaping (IAB10-8)',
    group: 'Home & Garden',
    iab_id: 'IAB10-8',
    parent_id: 189,
  },
  {
    id: 198,
    name: 'Remodeling & Construction (IAB10-9)',
    group: 'Home & Garden',
    iab_id: 'IAB10-9',
    parent_id: 189,
  },
  {
    id: 200,
    name: 'Immigration (IAB11-1)',
    group: "Law Gov't & Politics",
    iab_id: 'IAB11-1',
    parent_id: 199,
  },
  {
    id: 201,
    name: 'Legal Issues (IAB11-2)',
    group: "Law Gov't & Politics",
    iab_id: 'IAB11-2',
    parent_id: 199,
  },
  {
    id: 202,
    name: 'U.S. Government Resources (IAB11-3)',
    group: "Law Gov't & Politics",
    iab_id: 'IAB11-3',
    parent_id: 199,
  },
  {
    id: 203,
    name: 'Politics (IAB11-4)',
    group: "Law Gov't & Politics",
    iab_id: 'IAB11-4',
    parent_id: 199,
  },
  {
    id: 204,
    name: 'Commentary (IAB11-5)',
    group: "Law Gov't & Politics",
    iab_id: 'IAB11-5',
    parent_id: 199,
  },
  {
    id: 206,
    name: 'International News (IAB12-1)',
    group: 'News',
    iab_id: 'IAB12-1',
    parent_id: 205,
  },
  { id: 207, name: 'National News (IAB12-2)', group: 'News', iab_id: 'IAB12-2', parent_id: 205 },
  { id: 208, name: 'Local News (IAB12-3)', group: 'News', iab_id: 'IAB12-3', parent_id: 205 },
  {
    id: 210,
    name: 'Beginning Investing (IAB13-1)',
    group: 'Personal Finance',
    iab_id: 'IAB13-1',
    parent_id: 209,
  },
  {
    id: 211,
    name: 'Credit/Debt & Loans (IAB13-2)',
    group: 'Personal Finance',
    iab_id: 'IAB13-2',
    parent_id: 209,
  },
  {
    id: 212,
    name: 'Financial News (IAB13-3)',
    group: 'Personal Finance',
    iab_id: 'IAB13-3',
    parent_id: 209,
  },
  {
    id: 213,
    name: 'Financial Planning (IAB13-4)',
    group: 'Personal Finance',
    iab_id: 'IAB13-4',
    parent_id: 209,
  },
  {
    id: 214,
    name: 'Hedge Fund (IAB13-5)',
    group: 'Personal Finance',
    iab_id: 'IAB13-5',
    parent_id: 209,
  },
  {
    id: 215,
    name: 'Insurance (IAB13-6)',
    group: 'Personal Finance',
    iab_id: 'IAB13-6',
    parent_id: 209,
  },
  {
    id: 216,
    name: 'Investing (IAB13-7)',
    group: 'Personal Finance',
    iab_id: 'IAB13-7',
    parent_id: 209,
  },
  {
    id: 217,
    name: 'Mutual Funds (IAB13-8)',
    group: 'Personal Finance',
    iab_id: 'IAB13-8',
    parent_id: 209,
  },
  {
    id: 218,
    name: 'Options (IAB13-9)',
    group: 'Personal Finance',
    iab_id: 'IAB13-9',
    parent_id: 209,
  },
  {
    id: 219,
    name: 'Retirement Planning (IAB13-10)',
    group: 'Personal Finance',
    iab_id: 'IAB13-10',
    parent_id: 209,
  },
  {
    id: 220,
    name: 'Stocks (IAB13-11)',
    group: 'Personal Finance',
    iab_id: 'IAB13-11',
    parent_id: 209,
  },
  {
    id: 221,
    name: 'Tax Planning (IAB13-12)',
    group: 'Personal Finance',
    iab_id: 'IAB13-12',
    parent_id: 209,
  },
  { id: 223, name: 'Dating (IAB14-1)', group: 'Society', iab_id: 'IAB14-1', parent_id: 222 },
  {
    id: 224,
    name: 'Divorce Support (IAB14-2)',
    group: 'Society',
    iab_id: 'IAB14-2',
    parent_id: 222,
  },
  { id: 225, name: 'Gay Life (IAB14-3)', group: 'Society', iab_id: 'IAB14-3', parent_id: 222 },
  { id: 226, name: 'Marriage (IAB14-4)', group: 'Society', iab_id: 'IAB14-4', parent_id: 222 },
  { id: 227, name: 'Senior Living (IAB14-5)', group: 'Society', iab_id: 'IAB14-5', parent_id: 222 },
  { id: 228, name: 'Teens (IAB14-6)', group: 'Society', iab_id: 'IAB14-6', parent_id: 222 },
  { id: 229, name: 'Weddings (IAB14-7)', group: 'Society', iab_id: 'IAB14-7', parent_id: 222 },
  {
    id: 230,
    name: 'Ethnic Specific (IAB14-8)',
    group: 'Society',
    iab_id: 'IAB14-8',
    parent_id: 222,
  },
  { id: 232, name: 'Astrology (IAB15-1)', group: 'Science', iab_id: 'IAB15-1', parent_id: 231 },
  { id: 233, name: 'Biology (IAB15-2)', group: 'Science', iab_id: 'IAB15-2', parent_id: 231 },
  { id: 234, name: 'Chemistry (IAB15-3)', group: 'Science', iab_id: 'IAB15-3', parent_id: 231 },
  { id: 235, name: 'Geology (IAB15-4)', group: 'Science', iab_id: 'IAB15-4', parent_id: 231 },
  {
    id: 236,
    name: 'Paranormal Phenomena (IAB15-5)',
    group: 'Science',
    iab_id: 'IAB15-5',
    parent_id: 231,
  },
  { id: 237, name: 'Physics (IAB15-6)', group: 'Science', iab_id: 'IAB15-6', parent_id: 231 },
  {
    id: 238,
    name: 'Space/Astronomy (IAB15-7)',
    group: 'Science',
    iab_id: 'IAB15-7',
    parent_id: 231,
  },
  { id: 239, name: 'Geography (IAB15-8)', group: 'Science', iab_id: 'IAB15-8', parent_id: 231 },
  { id: 240, name: 'Botany (IAB15-9)', group: 'Science', iab_id: 'IAB15-9', parent_id: 231 },
  { id: 241, name: 'Weather (IAB15-10)', group: 'Science', iab_id: 'IAB15-10', parent_id: 231 },
  { id: 243, name: 'Aquariums (IAB16-1)', group: 'Pets', iab_id: 'IAB16-1', parent_id: 242 },
  { id: 244, name: 'Birds (IAB16-2)', group: 'Pets', iab_id: 'IAB16-2', parent_id: 242 },
  { id: 245, name: 'Cats (IAB16-3)', group: 'Pets', iab_id: 'IAB16-3', parent_id: 242 },
  { id: 246, name: 'Dogs (IAB16-4)', group: 'Pets', iab_id: 'IAB16-4', parent_id: 242 },
  { id: 247, name: 'Large Animals (IAB16-5)', group: 'Pets', iab_id: 'IAB16-5', parent_id: 242 },
  { id: 248, name: 'Reptiles (IAB16-6)', group: 'Pets', iab_id: 'IAB16-6', parent_id: 242 },
  {
    id: 249,
    name: 'Veterinary Medicine (IAB16-7)',
    group: 'Pets',
    iab_id: 'IAB16-7',
    parent_id: 242,
  },
  { id: 251, name: 'Auto Racing (IAB17-1)', group: 'Sports', iab_id: 'IAB17-1', parent_id: 250 },
  { id: 252, name: 'Baseball (IAB17-2)', group: 'Sports', iab_id: 'IAB17-2', parent_id: 250 },
  { id: 253, name: 'Bicycling (IAB17-3)', group: 'Sports', iab_id: 'IAB17-3', parent_id: 250 },
  { id: 254, name: 'Bodybuilding (IAB17-4)', group: 'Sports', iab_id: 'IAB17-4', parent_id: 250 },
  { id: 255, name: 'Boxing (IAB17-5)', group: 'Sports', iab_id: 'IAB17-5', parent_id: 250 },
  {
    id: 256,
    name: 'Canoeing/Kayaking (IAB17-6)',
    group: 'Sports',
    iab_id: 'IAB17-6',
    parent_id: 250,
  },
  { id: 257, name: 'Cheerleading (IAB17-7)', group: 'Sports', iab_id: 'IAB17-7', parent_id: 250 },
  { id: 258, name: 'Climbing (IAB17-8)', group: 'Sports', iab_id: 'IAB17-8', parent_id: 250 },
  { id: 259, name: 'Cricket (IAB17-9)', group: 'Sports', iab_id: 'IAB17-9', parent_id: 250 },
  {
    id: 260,
    name: 'Figure Skating (IAB17-10)',
    group: 'Sports',
    iab_id: 'IAB17-10',
    parent_id: 250,
  },
  { id: 261, name: 'Fly Fishing (IAB17-11)', group: 'Sports', iab_id: 'IAB17-11', parent_id: 250 },
  { id: 262, name: 'Football (IAB17-12)', group: 'Sports', iab_id: 'IAB17-12', parent_id: 250 },
  {
    id: 263,
    name: 'Freshwater Fishing (IAB17-13)',
    group: 'Sports',
    iab_id: 'IAB17-13',
    parent_id: 250,
  },
  { id: 264, name: 'Game & Fish (IAB17-14)', group: 'Sports', iab_id: 'IAB17-14', parent_id: 250 },
  { id: 265, name: 'Golf (IAB17-15)', group: 'Sports', iab_id: 'IAB17-15', parent_id: 250 },
  { id: 266, name: 'Horse Racing (IAB17-16)', group: 'Sports', iab_id: 'IAB17-16', parent_id: 250 },
  { id: 267, name: 'Horses (IAB17-17)', group: 'Sports', iab_id: 'IAB17-17', parent_id: 250 },
  {
    id: 268,
    name: 'Hunting/Shooting (IAB17-18)',
    group: 'Sports',
    iab_id: 'IAB17-18',
    parent_id: 250,
  },
  {
    id: 269,
    name: 'Inline Skating (IAB17-19)',
    group: 'Sports',
    iab_id: 'IAB17-19',
    parent_id: 250,
  },
  { id: 270, name: 'Martial Arts (IAB17-20)', group: 'Sports', iab_id: 'IAB17-20', parent_id: 250 },
  {
    id: 271,
    name: 'Mountain Biking (IAB17-21)',
    group: 'Sports',
    iab_id: 'IAB17-21',
    parent_id: 250,
  },
  {
    id: 272,
    name: 'NASCAR Racing (IAB17-22)',
    group: 'Sports',
    iab_id: 'IAB17-22',
    parent_id: 250,
  },
  { id: 273, name: 'Olympics (IAB17-23)', group: 'Sports', iab_id: 'IAB17-23', parent_id: 250 },
  { id: 274, name: 'Paintball (IAB17-24)', group: 'Sports', iab_id: 'IAB17-24', parent_id: 250 },
  {
    id: 275,
    name: 'Power & Motorcycles (IAB17-25)',
    group: 'Sports',
    iab_id: 'IAB17-25',
    parent_id: 250,
  },
  {
    id: 276,
    name: 'Pro Basketball (IAB17-26)',
    group: 'Sports',
    iab_id: 'IAB17-26',
    parent_id: 250,
  },
  {
    id: 277,
    name: 'Pro Ice Hockey (IAB17-27)',
    group: 'Sports',
    iab_id: 'IAB17-27',
    parent_id: 250,
  },
  { id: 278, name: 'Rodeo (IAB17-28)', group: 'Sports', iab_id: 'IAB17-28', parent_id: 250 },
  { id: 279, name: 'Rugby (IAB17-29)', group: 'Sports', iab_id: 'IAB17-29', parent_id: 250 },
  {
    id: 280,
    name: 'Running/Jogging (IAB17-30)',
    group: 'Sports',
    iab_id: 'IAB17-30',
    parent_id: 250,
  },
  { id: 281, name: 'Sailing (IAB17-31)', group: 'Sports', iab_id: 'IAB17-31', parent_id: 250 },
  {
    id: 282,
    name: 'Saltwater Fishing (IAB17-32)',
    group: 'Sports',
    iab_id: 'IAB17-32',
    parent_id: 250,
  },
  { id: 283, name: 'Scuba Diving (IAB17-33)', group: 'Sports', iab_id: 'IAB17-33', parent_id: 250 },
  {
    id: 284,
    name: 'Skateboarding (IAB17-34)',
    group: 'Sports',
    iab_id: 'IAB17-34',
    parent_id: 250,
  },
  { id: 285, name: 'Skiing (IAB17-35)', group: 'Sports', iab_id: 'IAB17-35', parent_id: 250 },
  { id: 286, name: 'Snowboarding (IAB17-36)', group: 'Sports', iab_id: 'IAB17-36', parent_id: 250 },
  {
    id: 287,
    name: 'Surfing/Bodyboarding (IAB17-37)',
    group: 'Sports',
    iab_id: 'IAB17-37',
    parent_id: 250,
  },
  { id: 288, name: 'Swimming (IAB17-38)', group: 'Sports', iab_id: 'IAB17-38', parent_id: 250 },
  {
    id: 289,
    name: 'Table Tennis/Ping-Pong (IAB17-39)',
    group: 'Sports',
    iab_id: 'IAB17-39',
    parent_id: 250,
  },
  { id: 290, name: 'Tennis (IAB17-40)', group: 'Sports', iab_id: 'IAB17-40', parent_id: 250 },
  { id: 291, name: 'Volleyball (IAB17-41)', group: 'Sports', iab_id: 'IAB17-41', parent_id: 250 },
  { id: 292, name: 'Walking (IAB17-42)', group: 'Sports', iab_id: 'IAB17-42', parent_id: 250 },
  {
    id: 293,
    name: 'Waterski/Wakeboard (IAB17-43)',
    group: 'Sports',
    iab_id: 'IAB17-43',
    parent_id: 250,
  },
  { id: 294, name: 'World Soccer (IAB17-44)', group: 'Sports', iab_id: 'IAB17-44', parent_id: 250 },
  {
    id: 296,
    name: 'Beauty (IAB18-1)',
    group: 'Style & Fashion',
    iab_id: 'IAB18-1',
    parent_id: 295,
  },
  {
    id: 297,
    name: 'Body Art (IAB18-2)',
    group: 'Style & Fashion',
    iab_id: 'IAB18-2',
    parent_id: 295,
  },
  {
    id: 298,
    name: 'Fashion (IAB18-3)',
    group: 'Style & Fashion',
    iab_id: 'IAB18-3',
    parent_id: 295,
  },
  {
    id: 299,
    name: 'Jewelry (IAB18-4)',
    group: 'Style & Fashion',
    iab_id: 'IAB18-4',
    parent_id: 295,
  },
  {
    id: 300,
    name: 'Clothing (IAB18-5)',
    group: 'Style & Fashion',
    iab_id: 'IAB18-5',
    parent_id: 295,
  },
  {
    id: 301,
    name: 'Accessories (IAB18-6)',
    group: 'Style & Fashion',
    iab_id: 'IAB18-6',
    parent_id: 295,
  },
  {
    id: 303,
    name: '3-D Graphics (IAB19-1)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-1',
    parent_id: 302,
  },
  {
    id: 304,
    name: 'Animation (IAB19-2)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-2',
    parent_id: 302,
  },
  {
    id: 305,
    name: 'Antivirus Software (IAB19-3)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-3',
    parent_id: 302,
  },
  {
    id: 306,
    name: 'C/C++ (IAB19-4)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-4',
    parent_id: 302,
  },
  {
    id: 307,
    name: 'Cameras & Camcorders (IAB19-5)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-5',
    parent_id: 302,
  },
  {
    id: 308,
    name: 'Cell Phones (IAB19-6)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-6',
    parent_id: 302,
  },
  {
    id: 309,
    name: 'Computer Certification (IAB19-7)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-7',
    parent_id: 302,
  },
  {
    id: 310,
    name: 'Computer Networking (IAB19-8)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-8',
    parent_id: 302,
  },
  {
    id: 311,
    name: 'Computer Peripherals (IAB19-9)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-9',
    parent_id: 302,
  },
  {
    id: 312,
    name: 'Computer Reviews (IAB19-10)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-10',
    parent_id: 302,
  },
  {
    id: 313,
    name: 'Data Centers (IAB19-11)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-11',
    parent_id: 302,
  },
  {
    id: 314,
    name: 'Databases (IAB19-12)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-12',
    parent_id: 302,
  },
  {
    id: 315,
    name: 'Desktop Publishing (IAB19-13)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-13',
    parent_id: 302,
  },
  {
    id: 316,
    name: 'Desktop Video (IAB19-14)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-14',
    parent_id: 302,
  },
  {
    id: 317,
    name: 'Email (IAB19-15)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-15',
    parent_id: 302,
  },
  {
    id: 318,
    name: 'Graphics Software (IAB19-16)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-16',
    parent_id: 302,
  },
  {
    id: 319,
    name: 'Home Video/DVD (IAB19-17)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-17',
    parent_id: 302,
  },
  {
    id: 320,
    name: 'Internet Technology (IAB19-18)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-18',
    parent_id: 302,
  },
  {
    id: 321,
    name: 'Java (IAB19-19)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-19',
    parent_id: 302,
  },
  {
    id: 322,
    name: 'JavaScript (IAB19-20)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-20',
    parent_id: 302,
  },
  {
    id: 323,
    name: 'Mac Support (IAB19-21)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-21',
    parent_id: 302,
  },
  {
    id: 324,
    name: 'MP3/MIDI (IAB19-22)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-22',
    parent_id: 302,
  },
  {
    id: 325,
    name: 'Net Conferencing (IAB19-23)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-23',
    parent_id: 302,
  },
  {
    id: 326,
    name: 'Net for Beginners (IAB19-24)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-24',
    parent_id: 302,
  },
  {
    id: 327,
    name: 'Network Security (IAB19-25)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-25',
    parent_id: 302,
  },
  {
    id: 328,
    name: 'Palmtops/PDAs (IAB19-26)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-26',
    parent_id: 302,
  },
  {
    id: 329,
    name: 'PC Support (IAB19-27)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-27',
    parent_id: 302,
  },
  {
    id: 330,
    name: 'Portable (IAB19-28)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-28',
    parent_id: 302,
  },
  {
    id: 331,
    name: 'Entertainment (IAB19-29)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-29',
    parent_id: 302,
  },
  {
    id: 332,
    name: 'Shareware/Freeware (IAB19-30)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-30',
    parent_id: 302,
  },
  {
    id: 333,
    name: 'Unix (IAB19-31)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-31',
    parent_id: 302,
  },
  {
    id: 334,
    name: 'Visual Basic (IAB19-32)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-32',
    parent_id: 302,
  },
  {
    id: 335,
    name: 'Web Clip Art (IAB19-33)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-33',
    parent_id: 302,
  },
  {
    id: 336,
    name: 'Web Design/HTML (IAB19-34)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-34',
    parent_id: 302,
  },
  {
    id: 337,
    name: 'Web Search (IAB19-35)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-35',
    parent_id: 302,
  },
  {
    id: 338,
    name: 'Windows (IAB19-36)',
    group: 'Technology & Computing',
    iab_id: 'IAB19-36',
    parent_id: 302,
  },
  {
    id: 340,
    name: 'Adventure Travel (IAB20-1)',
    group: 'Travel',
    iab_id: 'IAB20-1',
    parent_id: 339,
  },
  { id: 341, name: 'Africa (IAB20-2)', group: 'Travel', iab_id: 'IAB20-2', parent_id: 339 },
  { id: 342, name: 'Air Travel (IAB20-3)', group: 'Travel', iab_id: 'IAB20-3', parent_id: 339 },
  {
    id: 343,
    name: 'Australia & New Zealand (IAB20-4)',
    group: 'Travel',
    iab_id: 'IAB20-4',
    parent_id: 339,
  },
  {
    id: 344,
    name: 'Bed & Breakfasts (IAB20-5)',
    group: 'Travel',
    iab_id: 'IAB20-5',
    parent_id: 339,
  },
  { id: 345, name: 'Budget Travel (IAB20-6)', group: 'Travel', iab_id: 'IAB20-6', parent_id: 339 },
  {
    id: 346,
    name: 'Business Travel (IAB20-7)',
    group: 'Travel',
    iab_id: 'IAB20-7',
    parent_id: 339,
  },
  { id: 347, name: 'By US Locale (IAB20-8)', group: 'Travel', iab_id: 'IAB20-8', parent_id: 339 },
  { id: 348, name: 'Camping (IAB20-9)', group: 'Travel', iab_id: 'IAB20-9', parent_id: 339 },
  { id: 349, name: 'Canada (IAB20-10)', group: 'Travel', iab_id: 'IAB20-10', parent_id: 339 },
  { id: 350, name: 'Caribbean (IAB20-11)', group: 'Travel', iab_id: 'IAB20-11', parent_id: 339 },
  { id: 351, name: 'Cruises (IAB20-12)', group: 'Travel', iab_id: 'IAB20-12', parent_id: 339 },
  {
    id: 352,
    name: 'Eastern Europe (IAB20-13)',
    group: 'Travel',
    iab_id: 'IAB20-13',
    parent_id: 339,
  },
  { id: 353, name: 'Europe (IAB20-14)', group: 'Travel', iab_id: 'IAB20-14', parent_id: 339 },
  { id: 354, name: 'France (IAB20-15)', group: 'Travel', iab_id: 'IAB20-15', parent_id: 339 },
  { id: 355, name: 'Greece (IAB20-16)', group: 'Travel', iab_id: 'IAB20-16', parent_id: 339 },
  {
    id: 356,
    name: 'Honeymoons/Getaways (IAB20-17)',
    group: 'Travel',
    iab_id: 'IAB20-17',
    parent_id: 339,
  },
  { id: 357, name: 'Hotels (IAB20-18)', group: 'Travel', iab_id: 'IAB20-18', parent_id: 339 },
  { id: 358, name: 'Italy (IAB20-19)', group: 'Travel', iab_id: 'IAB20-19', parent_id: 339 },
  { id: 359, name: 'Japan (IAB20-20)', group: 'Travel', iab_id: 'IAB20-20', parent_id: 339 },
  {
    id: 360,
    name: 'Mexico & Central America (IAB20-21)',
    group: 'Travel',
    iab_id: 'IAB20-21',
    parent_id: 339,
  },
  {
    id: 361,
    name: 'National Parks (IAB20-22)',
    group: 'Travel',
    iab_id: 'IAB20-22',
    parent_id: 339,
  },
  {
    id: 362,
    name: 'South America (IAB20-23)',
    group: 'Travel',
    iab_id: 'IAB20-23',
    parent_id: 339,
  },
  { id: 363, name: 'Spas (IAB20-24)', group: 'Travel', iab_id: 'IAB20-24', parent_id: 339 },
  { id: 364, name: 'Theme Parks (IAB20-25)', group: 'Travel', iab_id: 'IAB20-25', parent_id: 339 },
  {
    id: 365,
    name: 'Traveling with Kids (IAB20-26)',
    group: 'Travel',
    iab_id: 'IAB20-26',
    parent_id: 339,
  },
  {
    id: 366,
    name: 'United Kingdom (IAB20-27)',
    group: 'Travel',
    iab_id: 'IAB20-27',
    parent_id: 339,
  },
  {
    id: 368,
    name: 'Apartments (IAB21-1)',
    group: 'Real Estate',
    iab_id: 'IAB21-1',
    parent_id: 367,
  },
  {
    id: 369,
    name: 'Architects (IAB21-2)',
    group: 'Real Estate',
    iab_id: 'IAB21-2',
    parent_id: 367,
  },
  {
    id: 370,
    name: 'Buying/Selling Homes (IAB21-3)',
    group: 'Real Estate',
    iab_id: 'IAB21-3',
    parent_id: 367,
  },
  {
    id: 372,
    name: 'Contests & Freebies (IAB22-1)',
    group: 'Shopping',
    iab_id: 'IAB22-1',
    parent_id: 371,
  },
  { id: 373, name: 'Couponing (IAB22-2)', group: 'Shopping', iab_id: 'IAB22-2', parent_id: 371 },
  { id: 374, name: 'Comparison (IAB22-3)', group: 'Shopping', iab_id: 'IAB22-3', parent_id: 371 },
  { id: 375, name: 'Engines (IAB22-4)', group: 'Shopping', iab_id: 'IAB22-4', parent_id: 371 },
  {
    id: 377,
    name: 'Alternative Religions (IAB23-1)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-1',
    parent_id: 376,
  },
  {
    id: 378,
    name: 'Atheism/Agnosticism (IAB23-2)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-2',
    parent_id: 376,
  },
  {
    id: 379,
    name: 'Buddhism (IAB23-3)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-3',
    parent_id: 376,
  },
  {
    id: 380,
    name: 'Catholicism (IAB23-4)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-4',
    parent_id: 376,
  },
  {
    id: 381,
    name: 'Christianity (IAB23-5)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-5',
    parent_id: 376,
  },
  {
    id: 382,
    name: 'Hinduism (IAB23-6)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-6',
    parent_id: 376,
  },
  {
    id: 383,
    name: 'Islam (IAB23-7)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-7',
    parent_id: 376,
  },
  {
    id: 384,
    name: 'Judaism (IAB23-8)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-8',
    parent_id: 376,
  },
  {
    id: 385,
    name: 'Latter-Day Saints (IAB23-9)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-9',
    parent_id: 376,
  },
  {
    id: 386,
    name: 'Pagan/Wiccan (IAB23-10)',
    group: 'Religion & Spirituality',
    iab_id: 'IAB23-10',
    parent_id: 376,
  },
  {
    id: 389,
    name: 'Unmoderated UGC (IAB25-1)',
    group: 'Non-Standard Content',
    iab_id: 'IAB25-1',
    parent_id: 388,
  },
  {
    id: 390,
    name: 'Extreme Graphic/Explicit Violence (IAB25-2)',
    group: 'Non-Standard Content',
    iab_id: 'IAB25-2',
    parent_id: 388,
  },
  {
    id: 391,
    name: 'Pornography (IAB25-3)',
    group: 'Non-Standard Content',
    iab_id: 'IAB25-3',
    parent_id: 388,
  },
  {
    id: 392,
    name: 'Profane Content (IAB25-4)',
    group: 'Non-Standard Content',
    iab_id: 'IAB25-4',
    parent_id: 388,
  },
  {
    id: 393,
    name: 'Hate Content (IAB25-5)',
    group: 'Non-Standard Content',
    iab_id: 'IAB25-5',
    parent_id: 388,
  },
  {
    id: 394,
    name: 'Under Construction (IAB25-6)',
    group: 'Non-Standard Content',
    iab_id: 'IAB25-6',
    parent_id: 388,
  },
  {
    id: 395,
    name: 'Incentivized (IAB25-7)',
    group: 'Non-Standard Content',
    iab_id: 'IAB25-7',
    parent_id: 388,
  },
  {
    id: 397,
    name: 'Illegal Content (IAB26-1)',
    group: 'Illegal Content',
    iab_id: 'IAB26-1',
    parent_id: 396,
  },
  { id: 398, name: 'Warez (IAB26-2)', group: 'Illegal Content', iab_id: 'IAB26-2', parent_id: 396 },
  {
    id: 399,
    name: 'Spyware/Malware (IAB26-3)',
    group: 'Illegal Content',
    iab_id: 'IAB26-3',
    parent_id: 396,
  },
  {
    id: 400,
    name: 'Copyright Infringement (IAB26-4)',
    group: 'Illegal Content',
    iab_id: 'IAB26-4',
    parent_id: 396,
  },
];
