import React from 'react';

import { Header } from 'components/header';
import '../../scss/index.scss';
import { Mainpage } from '../../pages/mainpage';

export const App = () => {
  return (
    <div>
      <Header />
      <Mainpage />
    </div>
  );
};
