import React from 'react';
import { Icon, Tooltip } from 'factor';

import styles from './bar.module.scss';

interface Props {
  title: string;
  percent: number;
}

export class Bar extends React.PureComponent<Props> {
  render() {
    const { title, percent } = this.props;

    return (
      <div className={styles.container}>
        <h4 className={`title-4 ${styles.title}`}>
          {title}
          <Tooltip label={title} className={styles.tooltip}>
            <Icon name="Question" />
          </Tooltip>
        </h4>
        <div className={styles.row}>
          <div className={`title-4 ${styles.label}`}>Fair</div>
          <div className={styles.bar}>
            <div className={styles.fill} style={{ width: `${percent}%` }} />
          </div>
          <div className={`title-4 ${styles.label}`}>High</div>
        </div>
      </div>
    );
  }
}
