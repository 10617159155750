export const testOptions = [
  { label: 'Option 1', value: 'Option 1' },
  { label: 'Option 2', value: 'Option 2' },
  { label: 'Option 3', value: 'Option 3' },
];

export const periods = [
  { title: 'Daily', value: 'Daily' },
  { title: 'Weekly', value: 'Weekly' },
  { title: 'Monthly', value: 'Monthly' },
];
