import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { logWarningXSS } from 'utils/log';
import messagesEn from 'translations/en.json';
import { App } from './components/app/App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  logWarningXSS();
}

const language: string = navigator.language.split(/[-_]/)[0];

interface IMessages {
  [key: string]: any;
}

const messages: IMessages = {
  en: messagesEn,
};

ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <App />
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
