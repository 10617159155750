import React from 'react';
import { ButtonsGroup, TextField, Tooltip, Icon } from 'factor';

import { Param as ParamInterface } from '../../models/Param';

import styles from './param.module.scss';

interface Props extends ParamInterface {
  className?: string;
}

export class Param extends React.PureComponent<Props> {
  render() {
    const {
      className = '',
      withBorder,
      withBorderBottom,
      title,
      tooltip,
      value,
      textField,
      buttonsGroup,
    } = this.props;

    return (
      <div
        className={`${styles.param} ${withBorder ? styles.withBorder : ''} ${
          withBorderBottom ? styles.borderBottom : ''
        } ${className}`}
      >
        <h4 className={styles.title}>
          {title}
          {tooltip && (
            <Tooltip label={tooltip} className={styles.tooltip}>
              <Icon name="Question" />
            </Tooltip>
          )}
        </h4>
        <b className={styles.value}>{value}</b>
        {(textField || buttonsGroup) && (
          <div className={`${styles.down} ${buttonsGroup ? styles.withButtons : ''}`}>
            <div className={styles.desired}>
              {textField && (
                <Tooltip label={textField.label} className={styles.textField}>
                  <TextField
                    className="_with-mobile"
                    label={textField.label}
                    value={textField.value}
                    onChange={textField.onChange}
                    withBorder
                  />
                </Tooltip>
              )}
              {buttonsGroup && (
                <ButtonsGroup
                  className="_with-mobile"
                  size="sm"
                  items={buttonsGroup.items}
                  value={buttonsGroup.value}
                  onChange={buttonsGroup.onChange}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
