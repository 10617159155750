import React from 'react';
import { Select, DatesPicker, Button } from 'factor';

import styles from './mainpage.module.scss';

import { Option } from '../../models/Option';
import { DateRange } from '../../models/DateRange';
import { Params } from '../../components/params';
import { Bar } from '../../components/bar';
import { Contacts } from '../../components/contacts';
import { ReactComponent as DownloadIcon } from '../../img/download.svg';
import {
  countries,
  states,
  deviceTypes,
  creativesTypesAndSizes,
  audiences,
  publisherCategories,
} from '../../components/data';

interface State {
  selects: {
    geoLocation?: Option | null;
    deviceType?: Option | null;
    creativeType?: Option | null;
    audience?: Option | null;
    publisher?: Option | null;
  };
  dateRange: DateRange;
  showEstimate: boolean;
  isMobileMode: boolean;
}

export class Mainpage extends React.Component<{}, State> {
  geoLocationOptions = countries.map((i: { name: string; id: number }) => {
    const statesOptions = states
      .filter((ii: any) => ii.country_id === i.id)
      .map((iii: any) => ({
        value: iii.id,
        label: `${iii.name} (${iii.abbreviation})`,
      }));
    const countriesOption: {
      value: number;
      label: string;
      options?: { value: number; label: string }[];
    } = {
      value: i.id,
      label: i.name,
    };

    if (statesOptions.length > 0) {
      countriesOption.options = statesOptions;
    }

    return countriesOption;
  });

  deviceTypes = deviceTypes.map((i: any) => ({
    value: i.id,
    label: i.name,
  }));

  audiencesOptions = audiences.map((i: any) => ({
    value: i.value,
    label: i.label,
    options: i.options.map((ii) => ({
      value: ii.id,
      label: ii.name,
    })),
  }));

  publishersGroups = publisherCategories.reduce((acc: any, i: any) => {
    if (acc.indexOf(i.group) === -1) {
      acc.push(i.group);
    }
    return acc;
  }, []);

  publishersOptions = this.publishersGroups.map((i: any) => {
    const innerOptions = publisherCategories
      .filter((ii: any) => ii.group === i)
      .map((iii: any) => ({
        value: iii.id,
        label: iii.name,
      }));
    return {
      value: i,
      label: i,
      options: innerOptions,
    };
  });

  constructor(props) {
    super(props);
    const startDate = new Date();
    const endDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    this.state = {
      selects: {
        geoLocation: null,
        deviceType: null,
        creativeType: null,
        audience: null,
        publisher: null,
      },
      dateRange: {
        start: startDate,
        end: endDate,
      },
      showEstimate: false,
      isMobileMode: false,
    };
  }

  componentDidMount() {
    this.setMobileMode();
    window.addEventListener('resize', this.setMobileMode);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMobileMode);
  }

  setMobileMode = () => {
    const { isMobileMode: isOldMobileMode } = this.state;
    const isMobileMode = window.innerWidth < 768;
    if (isMobileMode !== isOldMobileMode) {
      this.setState({ isMobileMode });
    }
  };

  handleSelectChange = (label: string) => (value: Option) => {
    const { selects } = this.state;
    this.setState({
      selects: {
        ...selects,
        [label]: value,
      },
    });
  };

  updateDateRange = (start: Date, end: Date) => {
    this.setState({
      dateRange: {
        start: new Date(start),
        end: new Date(end),
      },
    });
  };

  handleShoEstimateClick = () => {
    const { showEstimate } = this.state;
    this.setState({ showEstimate: !showEstimate });
  };

  render() {
    const { selects, isMobileMode } = this.state;
    const { geoLocation, deviceType, creativeType, audience, publisher } = selects;

    const { dateRange, showEstimate } = this.state;

    return (
      <main className={styles.main}>
        <header className={styles.header}>Let's find out how much your campaign will cost:</header>
        <div className={styles.container}>
          <div className={`${styles.settings}`}>
            <div className="container-1">
              <div className="row">
                <div className={`col-12 col-md-6 col-lg-4 ${styles.select}`}>
                  <Select
                    className="_with-mobile"
                    label="Geo Location"
                    placeholder="Select Geo Location"
                    options={this.geoLocationOptions}
                    onChange={this.handleSelectChange('geoLocation')}
                    value={geoLocation}
                    isMulti
                    allSelectable
                    isClearable
                  />
                </div>
                <div className={`col-12 col-md-6 col-lg-4 ${styles.select}`}>
                  <Select
                    className="_with-mobile"
                    label="Device Type"
                    placeholder="Select Device Type"
                    options={this.deviceTypes}
                    onChange={this.handleSelectChange('deviceType')}
                    value={deviceType}
                    isMulti
                    allSelectable
                    isClearable
                  />
                </div>
                <div className={`col-12 col-md-6 col-lg-4 ${styles.select} ${styles.creative}`}>
                  <Select
                    className="_with-mobile"
                    label="Creative Type"
                    placeholder="Select Creative Type and Size"
                    options={creativesTypesAndSizes}
                    onChange={this.handleSelectChange('creativeType')}
                    value={creativeType}
                    isMulti
                    allSelectable
                    isClearable
                  />
                </div>
                <div className={`col-12 col-md-6 col-lg-4 ${styles.select}`}>
                  <Select
                    className="_with-mobile"
                    label="Audience"
                    placeholder="Select Audience"
                    options={this.audiencesOptions}
                    onChange={this.handleSelectChange('audience')}
                    value={audience}
                    isMulti
                    allSelectable
                    isClearable
                  />
                </div>
                <div className={`col-12 col-md-6 col-lg-4 ${styles.select} ${styles.publisher}`}>
                  <Select
                    className="_with-mobile"
                    label="Publisher Category"
                    placeholder="Select Publisher Category"
                    options={this.publishersOptions}
                    onChange={this.handleSelectChange('publisher')}
                    value={publisher}
                    isMulti
                    allSelectable
                    isClearable
                  />
                </div>
                <div className={`col-12 col-md-6 col-lg-4 ${styles.select} ${styles.datepicker}`}>
                  <DatesPicker
                    className="_with-mobile"
                    dateRangePickerClassName="_with-mobile"
                    applyBtnClassName="_with-mobile"
                    updateDateRange={this.updateDateRange}
                    dateFormat="MM/DD/YYYY"
                    dateRange={dateRange}
                    datePickerProps={{
                      numberOfCalendars: 2,
                      maximumDate: new Date(),
                      animationAxis: isMobileMode ? 'Y' : undefined,
                    }}
                  />
                </div>
              </div>
            </div>
            {!showEstimate && (
              <Button
                size="lg"
                className={`btn-square _with-mobile _filled _conflower-blue ${styles.showEstimate}`}
                onClick={this.handleShoEstimateClick}
              >
                Show Estimate
              </Button>
            )}
          </div>
          {showEstimate && (
            <>
              <Params />
              <div className={`${styles.bars}`}>
                <div className="container-1">
                  <div className="row">
                    <div className={`col-12 col-md-6 ${styles.bar}`}>
                      <Bar title="Quality of Inventory" percent={83.333} />
                    </div>
                    <div className={`col-12 col-md-6 ${styles.bar}`}>
                      <Bar title="Viewability of Inventory" percent={83.333} />
                    </div>
                  </div>
                </div>
              </div>
              <Contacts className={styles.contacts} />
              <Button
                className={`btn-round _with-mobile _lg _conflower-blue _filled ${styles.download}`}
              >
                Download a quote
                <DownloadIcon />
              </Button>
              <p className={`text ${styles.comment}`}>
                You'll also recieve an email with the attached quote.
              </p>
            </>
          )}
        </div>
      </main>
    );
  }
}
