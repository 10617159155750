import React from 'react';
import { TextField } from 'factor';
import styles from './contacts.module.scss';

interface Props {
  className?: string;
}

interface TextField {
  label: string;
  value: string;
}

interface State {
  textFields: TextField[];
}

export class Contacts extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      textFields: [
        {
          label: 'Work Email*',
          value: '',
        },
        {
          label: 'Your Name*',
          value: '',
        },
        {
          label: 'Position in the Company*',
          value: '',
        },
      ],
    };
  }

  handleTextFieldChange = (label: string) => (value: string) => {
    const { textFields } = this.state;
    const textField = textFields.filter((i: TextField) => i.label === label)[0];
    textField.value = value;
    this.setState({ textFields });
  };

  render() {
    const { className = '' } = this.props;
    const { textFields } = this.state;

    return (
      <div className={`${styles.contacts} ${className}`}>
        <div className="container-1">
          <div className="row">
            {textFields.map((i: TextField) => (
              <div className={`col-12 col-md-4 ${styles.item}`} key={i.label}>
                <TextField
                  className="_with-mobile"
                  label={i.label}
                  value={i.value}
                  onChange={this.handleTextFieldChange(i.label)}
                  withBorder
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
