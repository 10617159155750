import React from 'react';

import { Param } from '../param';
import { Param as ParamInterface } from '../../models/Param';
import { periods } from '../consts';

import styles from './params.module.scss';

interface State {
  params: ParamInterface[];
}

export class Params extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      params: [
        {
          withBorder: true,
          title: 'Suggested CPM',
          tooltip: 'Suggested CPM',
          value: '$4.5 - $5.2',
          textField: {
            label: 'Desired CPM',
            value: '',
          },
        },
        {
          withBorder: true,
          title: 'Spending Capacity',
          tooltip: 'Spending Capacity',
          value: '$125,000 +',
          textField: {
            label: 'Desired Budget',
            value: '',
          },
        },
        {
          withBorder: true,
          title: 'Estimated Impressions',
          tooltip: 'Estimated Impressions',
          value: '50K - 70K',
          buttonsGroup: {
            items: periods,
            value: periods[1].value,
          },
        },
        {
          title: 'Estimated Reach',
          tooltip: 'Estimated Reach',
          value: '232K - 268K',
        },
        {
          title: 'Estimated Frequency',
          tooltip: 'Estimated Frequency',
          value: '3-5',
        },
        {
          title: 'Click Through Rate',
          tooltip: 'Click Through Rate',
          value: '1.2% - 1.7%',
        },
      ],
    };
  }

  handleParamTexFieldChange = (title: string) => (value: string) => {
    const { params } = this.state;
    const param = params.filter((i: any) => i.title === title)[0];
    if (param.textField) {
      param.textField.value = value;
      this.setState({ params });
    }
  };

  handleButtonsGroupChange = (title: string) => (value: string) => {
    const { params } = this.state;
    const param = params.filter((i: any) => i.title === title)[0];
    if (param.buttonsGroup) {
      param.buttonsGroup.value = value;
      this.setState({ params });
    }
  };

  render() {
    const { params } = this.state;

    return (
      <div className={`container-1 ${styles.params}`}>
        <h2 className={`text ${styles.title}`}>
          Adjust your campaign parameters above to get more accurate estimate
        </h2>
        <div className="row">
          {params.map((i: any, k) => {
            let onChange;
            if (i.textField) {
              onChange = this.handleParamTexFieldChange(i.title);
            }
            if (i.buttonsGroup) {
              onChange = this.handleButtonsGroupChange(i.title);
            }
            return (
              <div className={`col-12 col-lg-4 ${styles.item}`} key={i.title}>
                <Param
                  withBorderBottom={!(i.withBorder || k + 1 === params.length)}
                  withBorder={i.withBorder}
                  tooltip={i.tooltip}
                  title={i.title}
                  value={i.value}
                  textField={
                    i.textField
                      ? {
                          ...i.textField,
                          onChange,
                        }
                      : null
                  }
                  buttonsGroup={
                    i.buttonsGroup
                      ? {
                          ...i.buttonsGroup,
                          onChange,
                        }
                      : null
                  }
                  key={i.title}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
